import React from "react";
import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import { GatsbyImage } from "gatsby-plugin-image";

import { Col, Container, Row } from "react-bootstrap";

import Layout from "../components/common/Layout";
import ProductSlider from "../components/elements/ProductSlider";
import AnchorPrismic from "../components/elements/AnchorPrismic";
import { ArrowDownBig } from "../helpers/Icons";

import { nextMonday } from "../helpers";

import "../styles/pages/chef.scss";

const ChefTemplate = ({ data }) => {
  if (!data) return null;

  const chef = data.prismicChef?.data;
  const products = data.allPrismicProduct?.edges;

  return (
    <Layout>
      <article className="chef">
        <Container>
          <div className="pt-5 pb-6 text-center">
            <p className="h4">Chef</p>
            <ArrowDownBig size={58} />
          </div>
          <div className="chef__content mb-8 pb-3 pb-lg-6">
            <Row>
              {chef.image.gatsbyImageData && (
                <Col lg={6} className="order-lg-2">
                  <GatsbyImage
                    image={chef.image.gatsbyImageData}
                    alt={chef.image.alt || chef.name || ""}
                    className="bg-dark"
                  />
                </Col>
              )}
              <Col lg={6} className="px-4 px-lg-6">
                <h1 className="h4 mt-5 mt-lg-6 mb-4">{chef.name}</h1>
                <RichText
                  render={chef.description.richText}
                  serializeHyperlink={AnchorPrismic}
                />
              </Col>
            </Row>
          </div>
        </Container>
      </article>

      {/* ----- Products ----- */}
      {products && (
        <aside className="related-products mb-5">
          <Container>
            <div className="pb-4 text-center">
              <h2 className="h4">
                Περισσότερα στο μενού της εβδομάδας, {nextMonday()}
              </h2>
              <ArrowDownBig size={58} />
            </div>
            <ProductSlider data={products} />
          </Container>
        </aside>
      )}
    </Layout>
  );
};

export const query = graphql`
  query ChefQuery($id: String) {
    prismicChef(id: { eq: $id }) {
      ...prismicChefFragment
    }
    allPrismicProduct(limit: 4) {
      edges {
        node {
          ...prismicCardProductFragment
        }
      }
    }
  }
`;

export default ChefTemplate;
